import React, { Component } from 'react'
import './styles.scss'

class CircularProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const sqSize = this.props.sqSize
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2
    const viewBox = `0 0 ${sqSize} ${sqSize}`
    const dashArray = radius * Math.PI * 2
    const dashOffset = dashArray - (dashArray * this.props.percentage) / 100

    return (
      <svg
        width={this.props.sqSize}
        height={this.props.sqSize}
        viewBox={viewBox}
      >
        <circle
          className="circle-background"
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
          transform={`rotate(0 ${this.props.sqSize / 2} ${
            this.props.sqSize / 2
          })`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
      </svg>
    )
  }
}

CircularProgressBar.defaultProps = {
  sqSize: 20,
  percentage: 25,
  strokeWidth: 3,
}

export { CircularProgressBar }
