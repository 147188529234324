import React, { useState, useEffect } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { App as AppWrapper, Loader, toast } from 'samespace-ui-kit'

import { Header, Notice, ErrorBoundry, UnAuthorizedPage } from './components'
import { SessionContext } from './context/session'
import { ThemeProvider } from './context/theme'
import { isInsideIframe } from './utils/cti'
import { removeCookie, getCookie } from './utils/cookies'
import { parseJwt } from './utils/session'
import 'samespace-ui-kit/static/styles.css'
import './assets/icons/style.css'
import './assets/styles.scss'
import axios from 'axios'

const ManageV1 = React.lazy(() =>
  import(/* webpackChunkName: 'managev1' */ './sections/ManageV1/')
)
const Studio = React.lazy(() =>
  import(/* webpackChunkName: 'studio' */ './sections/Studio/')
)
const Insights = React.lazy(() =>
  import(/* webpackChunkName: 'insights' */ './sections/Insights/')
)
const Analytics = React.lazy(() =>
  import(/* webpackChunkName: 'analytics' */ './sections/Analytics/')
)
const Autodialer = React.lazy(() =>
  import(/* webpackChunkName: 'automate' */ './sections/Autodialer/')
)

const SSO = React.lazy(() =>
  import(/* webpackChunkName: 'sso' */ './pages/SSO')
)
const Login = React.lazy(() =>
  import(/* webpackChunkName: 'login' */ './pages/Login')
)
const InviteUser = React.lazy(() =>
  import(/* webpackChunkName: 'invite' */ './pages/Invite')
)
const VerifyEmail = React.lazy(() =>
  import(/* webpackChunkName: 'verify-email' */ './pages/VerifyEmail')
)
const ResendVerificationLink = React.lazy(() =>
  import(
    /* webpackChunkName: 'resend-verify-link' */ './pages/ResendVerificationLink'
  )
)
const SignUp = React.lazy(() =>
  import(/* webpackChunkName: 'signup' */ './pages/SignUp')
)
const Password = React.lazy(() =>
  import(/* webpackChunkName: 'password' */ './pages/Password')
)
const Activity = React.lazy(() =>
  import(/* webpackChunkName: 'activity' */ './pages/Activity')
)
const ActivityNew = React.lazy(() =>
  import(/* webpackChunkName: 'activity' */ './pages/ActivityNew')
)
const Billing = React.lazy(() =>
  import(/* webpackChunkName: 'billing' */ './pages/Billing')
)

function App() {
  const [sessionExpired, setSessionExpired] = useState(false)
  const manage = getCookie('manage') === 'true'
  const insights = getCookie('insights') === 'true'
  const admin = getCookie('admin') === 'true'
  const agent = !admin && !manage && !insights

  useEffect(() => {
    const emailVerified = getCookie('emailVerified')
    const daysToVerify = getCookie('daysToVerify')
    const refreshToken = getCookie('refreshToken')
    if (refreshToken) {
      let token = parseJwt(refreshToken)
      if (Date.now() >= new Date(token.exp * 1000)) {
        setSessionExpired(true)
      }
    }

    if (emailVerified === 'false' && Number(daysToVerify) > 0) {
      toast.error(
        `Verify your email within ${daysToVerify} ${
          daysToVerify > 1 ? 'days' : 'day'
        } ${
          Number(daysToVerify) <= 2
            ? 'or you will not be able to signin after that'
            : ''
        } `
      )
    } else if (emailVerified === 'true') {
      axios
        .get('/manage/api/sso')
        .then(async (response) => {
          const { SSOEnabled } = response.data
          if (!SSOEnabled) {
            toast.success('Email Verified')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    removeCookie('emailVerified')
    removeCookie('daysToVerify')
  }, [])

  const isInIFrame = isInsideIframe()

  return (
    <SessionContext.Consumer>
      {({ session }) => (
        <ThemeProvider session={session}>
          <div className={`app ${isInIFrame && 'is-in-iframe'}`}>
            <AppWrapper basename="/">
              {({ history }) => {
                return (
                  <>
                    {!isInIFrame &&
                      session.loggedIn &&
                      history.location.pathname !== '/' &&
                      !history.location.pathname.includes('sso/authorize') &&
                      !history.location.pathname.includes('email/verify') &&
                      !history.location.pathname.includes('account/status') &&
                      !history.location.pathname.includes('password') &&
                      !history.location.pathname.includes('invite') &&
                      !history.location.pathname.includes('signin') &&
                      !history.location.pathname.includes('signup') &&
                      !sessionExpired && (
                        <>
                          <Header />
                          <Notice />
                        </>
                      )}
                    <Router history={history}>
                      <React.Suspense fallback={<Loader />}>
                        {session.loaded && (
                          <ErrorBoundry>
                            <Switch>
                              <Route exact path="/">
                                <Login />
                              </Route>
                              <Route exact path="/sso/authorize">
                                <SSO />
                              </Route>
                              <Route exact path="/invite/:inviteId?">
                                <InviteUser />
                              </Route>
                              <Route exact path="/email/verify/:userId?">
                                <VerifyEmail />
                              </Route>
                              <Route exact path="/account/status/:userId?">
                                <ResendVerificationLink />
                              </Route>
                              <Route exact path="/signin/:cloudId?">
                                <Login />
                              </Route>
                              <Route exact path="/signup/v2/:key">
                                <SignUp />
                              </Route>
                              <Route exact path="/signup/:key">
                                <SignUp />
                              </Route>
                              <Route exact path="/password/:page">
                                <Password />
                              </Route>
                              {session.loggedIn && !sessionExpired ? (
                                <>
                                  <Route exact path="/activity/old">
                                    {manage || admin ? (
                                      <Activity />
                                    ) : (
                                      <UnAuthorizedPage />
                                    )}
                                  </Route>
                                  <Route exact path="/activity">
                                    {manage || admin ? (
                                      <ActivityNew />
                                    ) : (
                                      <UnAuthorizedPage />
                                    )}
                                  </Route>
                                  <Route exact path="/billing/:section?">
                                    {manage || admin ? (
                                      <Billing />
                                    ) : (
                                      <UnAuthorizedPage />
                                    )}
                                  </Route>
                                  <Route path="/(manage|campaigns|onboarding)">
                                    {manage || admin ? (
                                      <ManageV1 history={history} />
                                    ) : (
                                      <UnAuthorizedPage />
                                    )}
                                  </Route>
                                  <Route path="/settings">
                                    {admin ? (
                                      <ManageV1 history={history} />
                                    ) : (
                                      <UnAuthorizedPage />
                                    )}
                                  </Route>
                                  <Route path="/studio">
                                    {manage || admin ? (
                                      <Studio history={history} />
                                    ) : (
                                      <UnAuthorizedPage />
                                    )}
                                  </Route>
                                  <Route path="/insights">
                                    {!agent ? (
                                      <Insights history={history} />
                                    ) : (
                                      <UnAuthorizedPage />
                                    )}
                                  </Route>
                                  <Route path="/analytics">
                                    {!agent ? (
                                      <Analytics history={history} />
                                    ) : (
                                      <UnAuthorizedPage />
                                    )}
                                  </Route>
                                  <Route path="/playbooks">
                                    {manage || admin ? (
                                      <Autodialer history={history} />
                                    ) : (
                                      <UnAuthorizedPage />
                                    )}
                                  </Route>
                                  <Route path="/user-insights">
                                    <ManageV1 history={history} />
                                  </Route>
                                  <Route path="/stories">
                                    <ManageV1 history={history} />
                                  </Route>
                                </>
                              ) : (
                                <Redirect to="/" />
                              )}
                            </Switch>
                          </ErrorBoundry>
                        )}
                      </React.Suspense>
                    </Router>
                  </>
                )
              }}
            </AppWrapper>
          </div>
        </ThemeProvider>
      )}
    </SessionContext.Consumer>
  )
}

export default App
