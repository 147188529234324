import Cookies from 'universal-cookie'

const cookie = new Cookies()
const COOKIE_EXPIRY_IN_SEC = 30 * 24 * 3600 * 1000
const COOKIE_DOMAIN = window.location.hostname.includes('.samespace.com')
  ? '.samespace.com'
  : window.location.hostname

// const COOKIE_KEYS = [
//   'authToken',
//   'username',
//   'name',
//   'admin',
//   'manage',
//   'refreshToken',
//   'userId',
//   'domainId',
//   'insights',
//   'campaign',
//   'ivr',
//   'sipTrunk',
//   'mediaServer',
//   'verifyPhone',
//   'savedcloud',
//   'BASE_URL',
// ]

export function getAllCookie() {
  return cookie.getAll()
}
export function getCookie(key) {
  return cookie.get(key)
}
export function setCookie(key, value) {
  cookie.set(key, value, {
    path: '/',
    // httpOnly: true,
    maxAge: COOKIE_EXPIRY_IN_SEC,
    domain: COOKIE_DOMAIN,
    secure: true,
    sameSite: 'lax',
  })
}
export function removeCookie(key) {
  cookie.remove(key, {
    path: '/',
    domain: COOKIE_DOMAIN,
  })
}
