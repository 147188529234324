import React from 'react'

export const initialSession = {
  loaded: false,
  loggedIn: false,
  baseURL: null,
  authToken: null,
  refreshToken: null,
  user: null,
  privileges: {
    admin: false,
    insights: false,
    manager: false,
  },
  access: {
    campaign: false,
    ivr: false,
    mediaServer: false,
    sipTrunk: false,
    verifyPhone: false,
  },
}

export const SessionContext = React.createContext({
  session: initialSession,
  updateSession: () => {},
})
