import React, { useEffect, useRef } from 'react'
import { Button, Modal, ModalBody, ModalSection } from 'samespace-ui-kit'
import Cookies from 'universal-cookie'

import './styles.scss'

export function Notice() {
  const cookie = new Cookies()
  const modalRef = useRef(null)
  const noticeModalLocalStorage =
    JSON.parse(localStorage.getItem('NOTIFIED_DOMAINS')) || {}
  const savedcloud = cookie.get('savedcloud')

  useEffect(() => {
    localStorage.removeItem('NOTIFIED_DOMAINS')
  }, [])

  function hideModal() {
    noticeModalLocalStorage[savedcloud] = true
    localStorage.setItem(
      'NOTIFIED_DOMAINS',
      JSON.stringify(noticeModalLocalStorage)
    )
  }

  return (
    <>
      <Modal
        ref={modalRef}
        size="medium"
        showClose={false}
        allowEscape={false}
        onClose={() => hideModal()}
      >
        <ModalBody>
          <ModalSection>
            <div className="notice-modal">
              <div className="notice-modal__title">
                <img
                  alt=""
                  src={`https://www.samespace.com/assets/img/favicon.png`}
                />
                <h5>Important Update</h5>
              </div>
              <div className="notice-modal__body">
                <p>
                  It has come to our notice that many of our customers are not
                  adhering to the number of user licenses that they are paying
                  for. We never enforced the licenses on the system because we
                  trust our customers, however, this is causing overutilization
                  of our infrastructure and resources, support incidents and
                  increase in our cost. Therefore we have decided to strictly
                  enforce licensing with effect previous billing cycle i.e.{' '}
                  <b>1st April 2020.</b>
                </p>
                <p>
                  Please make sure that your number of users on Samespace is
                  equivalent to the number of user licenses requested by you or
                  you are paying for as per your purchase order.
                </p>
                <p>
                  If you would like to increase the user licenses, please get in
                  touch with your account manager and we will be happy to assist
                  you.
                </p>
                <p>Thanks for your cooperation. We appreciate your business.</p>
              </div>
              <div className="notice-modal__footer">
                <Button
                  rounded
                  accent="info"
                  onClick={e => modalRef.current.hide()}
                >
                  Got It
                </Button>
              </div>
            </div>
          </ModalSection>
        </ModalBody>
      </Modal>
    </>
  )
}
