import React, { Component } from 'react'

//import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import './styles.scss'

// const WIZARD_ANIMATION_DURATION = 500
class Transition extends Component {
  state = {
    height: 0,
  }

  static getDerivedStateFromProps(props, state) {
    return {
      height: props.children ? state.height : 0,
    }
  }

  render() {
    const { children, style: propStyle, activeClassName } = this.props
    const { height } = this.state
    let style = {
      height,
    }
    if (children && propStyle) {
      style = {
        ...style,
        ...this.props.style,
      }
    }
    return (
      <div
        className={`mount-transition ${children && activeClassName}`}
        style={style}
      >
        {children && (
          <div
            className="mount-transition-wrap"
            ref={ref => {
              ref &&
                ref.getBoundingClientRect() &&
                ref.getBoundingClientRect().height !== height &&
                this.setState({
                  height: ref.getBoundingClientRect().height,
                })
            }}
          >
            {children}
          </div>
        )}
      </div>
    )
  }
}

export { Transition }
