export const API_BASE = '/auth/api'
export const APIS = {
  DOMAIN: '/domain',
  LOGIN: '/login',
  REFRESH: '/refresh',
  FORGOT_PASSWORD: '/forgotpassword',
  VERIFY_PASSWORD_KEY: '/verifypasswordkey',
  RESET_PASSWORD: '/resetpassword',
  SIGNUP: '/account',
}
