import React, { useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import ReactPopover from 'react-popover'

import './style.scss'

export const Popover = forwardRef(
  ({ preferPlace, popup, closeOnOutsideClick, children, className }, ref) => {
    const [isOpen, setIsOpen] = useState(false)

    const show = () => {
      setIsOpen(true)
    }

    const hide = () => {
      setIsOpen(false)
    }

    useImperativeHandle(ref, () => {
      return {
        show: show,
        hide: hide,
      }
    })

    return (
      <div className="date-popover">
        <ReactPopover
          className="DatePopover"
          isOpen={isOpen}
          preferPlace={preferPlace}
          body={[popup]}
          tipSize={8}
          appendTarget={document.getElementsByClassName('app')[0]}
          onOuterAction={() => {
            if (closeOnOutsideClick) {
              setIsOpen(false)
            }
          }}
        >
          <span className="date-popover__trigger" onClick={() => setIsOpen(!isOpen)}>
            {children}
          </span>
        </ReactPopover>
      </div>
    )
  }
)

Popover.propTypes = {
  /** placement */
  preferPlace: PropTypes.oneOf(
    'start',
    'end',
    'above',
    'right',
    'below',
    'left',
    'column',
    'row'
  ),
  /** popup content */
  popup: PropTypes.node,
  /** close popup when clicked outside */
  closeOnOutsideClick: PropTypes.bool,
}

Popover.defaultProps = {
  search: [],
  content: '',
  closeOnOutsideClick: true,
}
