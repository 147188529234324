import React from 'react'

import './styles.scss'

export function LoginPage({ children }) {
  return <article className="login">{children}</article>
}

export function LoginPageContent({ children }) {
  return <article className="login-content">{children}</article>
}

export function LoginBox({ children, signUpMode }) {
  return (
    <article
      className={`login-content-wrapper ${signUpMode ? 'signup-mode' : ''}`}
    >
      {/* <article className="login-box__logo">
        <img src={require('../../assets/img/login-logo.svg')} alt="samespace" />
      </article> */}
      <>{children}</>
    </article>
  )
}

export function LoginFormBox({ children }) {
  return <article className="login-form">{children}</article>
}

export function LoginFormHeader({ title, description }) {
  return (
    <section className="login-form__header">
      <span className="login-form__title">{title}</span>
      <p>{description}</p>
    </section>
  )
}

export function LoginFormBody({ children }) {
  return <section className="login-form__body">{children}</section>
}

export function LoginFormFooter({ children }) {
  return <section className="login-form__footer">{children}</section>
}
