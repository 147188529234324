import React from 'react'
import { EmptyState } from 'samespace-ui-kit'
import './styles.scss'

export const UnAuthorizedPage = () => {
  return (
    <div className="insufficient-permission--empty-state-wrapper">
      <EmptyState
        title={
          <span className="empty-state-title">Insufficient Permissions</span>
        }
        description="You do not have permission to access this page. If you think this is incorrect please contact your administrator"
        image={require('../../assets/img/insufficient-permission-image.png')}
      />
    </div>
  )
}
