const CTI_FRAME_PATHS = {
  ANALYTICS: '/analytics',
  AUTODIALER: '/playbooks',
  ORGANIZATION: '/manage/users',
  PULSE: '/insights/pulse-v2',
  SETTINGS: '/settings',
  STUDIO: '/studio',
}

export function isInsideIframe() {
  return !!window.name
}

export function getFrameURL() {
  const sectionName = window.name.split('-')[1]
  return CTI_FRAME_PATHS[sectionName]
}
