import React, { createContext, useState, useEffect, useCallback } from 'react'
import { Loader } from 'samespace-ui-kit'
import axios from 'axios'
import { getCookie, setCookie } from '../utils/cookies'

const setTheme = (color) => {
  const body = document.querySelector(`body`)
  return body?.style.setProperty('--primary', `${color}`)
}
const setThemeRgb = (color) => {
  const body = document.querySelector(`body`)
  return body?.style.setProperty('--primary-rgb', hexToRgb(`${color}`))
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null
}

export const ThemeContext = createContext()

export const ThemeProvider = ({ children, session }) => {
  const [accent, setAccent] = useState('')
  const [loading, setLoading] = useState(true)
  const userId = session && session.user && session.user.id
  const cloud = getCookie('savedcloud')
  const getTheme = useCallback(async () => {
    try {
      if (userId && cloud) {
        if (getCookie(`theme_${cloud}_${userId}`)) {
          setTheme(decodeURI(getCookie(`theme_${cloud}_${userId}`)))
          setThemeRgb(decodeURI(getCookie(`theme_${cloud}_${userId}`)))
          setLoading(false)
        } else {
          const { data } = await axios.get(
            `${getCookie('BASE_URL')}/manage/api/user/${userId}`,
            {
              headers: {
                authorization: `JWT ${getCookie('authToken')}`,
              },
            }
          )
          if (data && data.theme && data.theme.color) {
            setTheme(data.theme.color)
            setThemeRgb(data.theme.color)
            setLoading(false)
            setCookie(`theme_${cloud}_${userId}`, data.theme.color, {
              encode: (v) => v,
            })
          } else {
            setTheme('#4f46e5')
            setThemeRgb('#4f46e5')
            setLoading(false)
          }
        }
      } else {
        setTheme('#4f46e5')
        setThemeRgb('#4f46e5')
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      setTheme('#4f46e5')
      setThemeRgb('#4f46e5')
    }
  }, [cloud, userId])

  useEffect(() => {
    getTheme()
  }, [getTheme])

  return (
    <ThemeContext.Provider value={{ accent, setAccent }}>
      {loading ? (
        <div className="initial-loader">
          <Loader />
        </div>
      ) : (
        children
      )}
    </ThemeContext.Provider>
  )
}
