import React, { Component } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { HeaderBar, handleSignOut } from 'samespace-ui-kit'

import { COOKIE_DOMAIN } from '../../utils/constants'

const API_BASE = '/manage/api'
const AUTH_BASE = '/auth/api'

export class Header extends Component {
  constructor() {
    super()
    this.cookies = new Cookies()
  }

  componentDidMount() {
    const token = this.cookies.get('authToken')
    const refreshToken = this.cookies.get('refreshToken')

    if (token) {
      const AUTH_URL = this.cookies.get('BASE_URL') + AUTH_BASE
      axios.defaults.withCredentials = false
      axios.defaults.baseURL = this.cookies.get('BASE_URL') + API_BASE
      axios.defaults.headers['authorization'] = `JWT ${token}`

      axios.interceptors.response.use(undefined, (err) => {
        const orignalRequest = err.response ? err.response.config : {}
        if (
          err.response !== undefined &&
          err.response.status === 401 &&
          !orignalRequest.headers.refresh
        ) {
          return new Promise((resolve, reject) => {
            axios
              .post(`${AUTH_URL}/refresh`, null, {
                headers: {
                  token: `JWT ${token}`,
                  refresh: refreshToken,
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  this.cookie.set('authToken', response.data.token, {
                    path: '/',
                    maxAge: 2592000,
                    domain: COOKIE_DOMAIN,
                  })
                  this.cookie.set('refreshToken', response.data.token, {
                    path: '/',
                    maxAge: 2592000,
                    domain: COOKIE_DOMAIN,
                  })

                  orignalRequest.headers[
                    'authorization'
                  ] = `JWT ${response.data.token}`

                  axios.defaults.headers[
                    'authorization'
                  ] = `JWT ${response.data.token}`

                  resolve(axios(orignalRequest))
                } else {
                  this.requestSignOut({ forced: true })
                }
              })
              .catch((err) => {
                this.requestSignOut({ forced: true })
                reject(err)
              })
          })
        } else if (
          err.response !== undefined &&
          err.response.status === 401 &&
          orignalRequest.headers.refresh
        ) {
          this.requestSignOut()
        } else if (err.response && err.response.status === 403) {
          this.requestSignOut()
        }
        return Promise.reject(err.response)
      })
    }
  }

  requestSignOut = () => handleSignOut()

  render() {
    return <HeaderBar />
  }
}

export const SubHeaderBar = ({ children }) => {
  return <section className="subheader">{children}</section>
}
